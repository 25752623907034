import React from "react"

const PageNotFound = () => (
  <React.Fragment>
    <div
      style={{
        position: "absolute",
        display: "block",
        width: "100%",
        height: "100%",
        backgroundColor: "#fff",
      }}
    >
      <h2>
        Error! Link Unavailable{" "}
        <span role="img" aria-label="donut">
          😥
        </span>{" "}
      </h2>
    </div>
  </React.Fragment>
)

export default PageNotFound
